<template>
  <GenericPage>
    <template #description>
      <Breadcrumbs class="py-2 md:py-5" />
      <Separator />
      <h1 class="mt-4">{{ t("ui.breadcrumbs.categories", 2) }}</h1>
    </template>

    <SSRSuspense :status="status" :items="data?.categories?.edges">
      <template #success="{ items }">
        <CategoryListRoot>
          <CategoryListItem
            v-for="category in items"
            :key="category.node.id"
            :title="category.node.translation?.name ?? category.node.name"
            :subtitle="category.node.translation?.description ?? category.node.description ?? ''"
            :href="`/categories/${category.node.slug}`"
          />
        </CategoryListRoot>
      </template>
      <template #pending>
        <div class="mx-auto flex max-w-7xl flex-col gap-2 px-3 py-[60px] md:px-6">
          <CategoryListItemSkeleton v-for="i in 5" :key="i" />
        </div>
      </template>
      <template #empty>
        <p>{{ $t("categories.noCategoriesFound") }}</p>
      </template>
    </SSRSuspense>
  </GenericPage>
</template>
<script setup lang="ts">
import type { CategoriesQueryVariables } from "@graphql";
import { CategoriesDocument } from "@graphql";

import {
  ListItem as CategoryListItem,
  ListItemSkeleton as CategoryListItemSkeleton,
  ListRoot as CategoryListRoot,
} from "~/src/categories/components";
import { useChannel } from "~/src/channel/composables";

const { t } = useI18n();
const { languageCode } = useChannel();
const { data, status } = useAsyncQuery(
  {
    query: CategoriesDocument,
    // NOTE: specifying variables type cause @nuxtjs/apollo can't infer it from query
    // i believe it's a @nuxt/apollo bug
    variables: computed<CategoriesQueryVariables>(() => ({
      languageCode: languageCode.value,
    })),
  },
  { lazy: true }
);

useHead({
  title: t("categories.title", 2),
});
</script>
